import React from 'react'
import Header from '../../../Pages/Home/Header'
import Homes from '../../../Pages/Home/Home'
import Fotter from '../../../Container/Fotter/Fotter'
function Home() {
  return (
    <div>
     <Header/>
    <Homes/>
    <Fotter/>
    </div>
  )
}

export default Home