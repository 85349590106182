import React from "react";
import "./min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../Assets/Images/logo.png";
import { NavLink } from "react-router-dom";
import f from "./img/fa.png";
import I from "./img/insta.png";
import Y from "./img/you.png";
import Wa from "../Fotter/img/whatsapp.png";
import In from "./img/in.png";
function Fotter() {
  return (
    <div>
      <div className="d-flex flex-column h-100">
        <footer className="w-100 py-4 flex-shrink-0">
          <div className="container py-4">
            <div className="row gy-4 gx-5">
              <div className="col-lg-4 col-md-6">
                <h5 className="h1 text-white">
                  <img style={{ width: "105px" }} src={Logo} />
                </h5>
                <p
                  className="small text-muted"
                  style={{ color: "white" }}
                  id="fotterLink"
                >
                  Transforming Financial Vision into Reality - Askkca,
                  Professional Finance Expert Services{" "}
                </p>
                <br />
                <p
                  id="fotterLink"
                  style={{ color: "white" }}
                  className="small text-muted mb-0"
                >
                  © Copyrights. All rights reserved.{" "}
                  <a className="text-primary" href="askkca.com">
                    Kirti Corporate Advisory Private Limited
                  </a>
                </p>
              </div>
              <div className="col-lg-2 col-md-6">
                <h5
                  className="text-white mb-3"
                  style={{ color: "white" }}
                  id="fotterLink"
                >
                  Quick links
                </h5>
                <ul className="list-unstyled text-muted">
                  <li>
                    /
                    <NavLink to="" className="fotterLink">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="fotterLink" to="/about-us">
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="fotterLink" to="/contact-us">
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="fotterLink" to="/blogs">
                      Blogs
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6">
                <h5
                  className="text-white mb-3"
                  style={{ color: "white" }}
                  id="fotterLink"
                >
                  Services
                </h5>
                <ul className="list-unstyled text-muted">
                  <li>
                    <NavLink
                      className="fotterLink"
                      to="/services?id=Accounting-and-Bookkeeping-Services"
                    >
                      Accounting and Bookkeeping{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="fotterLink"
                      to="/services?id=Payroll-Management-and-Compliance-Services"
                    >
                      Payroll Management and Compliance{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="fotterLink"
                      to="/services?id=US-Book-Keeping-Services"
                    >
                      US Book Keeping{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="fotterLink"
                      to="/services?id=Direct-and-Indirect-Tax-Compliances"
                    >
                      Direct and Indirect Tax{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="fotterLink"
                      to="/services?id=Secretarial-Compliances"
                    >
                      Secretarial Compliances
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="fotterLink"
                      to="/services?id=Business-Registration"
                    >
                      Business Registration
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <h5 className="text-white mb-3">Visit Us</h5>
                <p
                  className="small text-muted"
                  id="fotterLink"
                  style={{ color: "white" }}
                >
                  4 Salkia School Road
                  <br />
                  Howrah - 711101 <br />
                </p>
                <br />

                <form action="#">
                  <div className="input-group mb-3">
                    <a href="">
                      <img
                        style={{ width: "35px", marginRight: "10px" }}
                        src={f}
                        alt=""
                      />
                    </a>
                    <a href="">
                      <img
                        style={{ width: "35px", marginRight: "10px" }}
                        src={In}
                        alt=""
                      />
                    </a>
                   
                    <a href="https://wa.me/9674000666">
                      <img
                        style={{ width: "35px", marginRight: "10px" }}
                        src={Wa}
                        alt=""
                      />
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Fotter;
