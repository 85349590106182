import React,{useEffect} from 'react';
import Header from '../../Assets/Images/Services.png';
import section1 from '../../Assets/Images/services1.jpg';
import Service2 from '../../Assets/Images/service2.png'
import './services.css'
import ContactBanner from '../../Container/Contact/ContactBanner';
function Services({data}) {
  const headerStyle = {
    backgroundImage: `url(${data?.section1?.img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
width: '100%',
height: '40vh',
position: 'relative',
marginTop: '11vh',
    // You can add more styles here if needed
  };
 
  
console.log(data)
  return (
    <div>
      <div className='headercontiner1' style={headerStyle}>
        <div className='headercontinerText' style={{width:"60%"}}>
          <h1 style={{fontSize: "32px", color: "white"}}>{data?.section1?.heading}</h1>
          <p style={{fontSize: "18px", color: "white"}}>{data?.section1?.text} </p>
        </div>
      </div>
      <div className="service_section2_parent">
        <div className="service_section2_child">
          <div className="service_section2_child1">
            <img
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "450px",
                minWidth: "200px",
                padding: "20px",
                borderRadius:"44px"
              }}
              src={data?.section2?.img}
              alt="Why SharpITS for Your IAM Implementation"
            />
          </div>
          <div className="service_section2_child2">
            <h3
              className="service_section2_child2_head"
              style={{ padding: "0px 10px" }}
            >
              {data?.section2?.heading}
            </h3>

            <p
              className="service_section2_child2_title"
              style={{ fontSize: "14px", padding: " 0px 10px" }}
            >
          {data?.section2?.text1}
<br/>

{data?.section2?.text2}
            </p>
          </div>
        </div>
      </div>
      <div  data-aos="fade-up" style={{display:"flex",justifyContent:"center",marginBottom:"20px"}}>
    <h2 style={{marginTop:"50px",maxWidth:"900px"}} className='Service_section2_maintitle' >{data?.section3?.heading}</h2>
    </div>
    <div className='Service_section2_container'>
        <div className='Service_section2_inner_container'>
          {
            data?.section3?.data.map((ell)=>(
<div  data-aos="fade-right" className='Service_section2_content_box'>
      <h5 className='service_section2_title' style={{fontSize:"17px"}}>{ell.heading}</h5>
      <p className='service_section2_text'>{ell.text}</p>
  </div>
            ))
          }
        
 
        </div>
</div>
<div
        className="SA_benifite"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <h3 className="SA_service_section3_title">
          {data?.section4?.heading}
          </h3>
        </div>
      </div>
      <div className="SA_service_section4">
        <div className="SA_service_section4_conatiner">
          <div
            data-aos="fade-right"
            className="SA_benefits_container"
            style={{ width: "320px" }}
          >
            <h4 className="SA_service_title" style={{ fontSize: "18px" }}>
              {" "}
              {data?.section4?.data[0]?.heading}
            </h4>
            <p className="SA_service_text">
            {data?.section4?.data[0]?.text}
            </p>
            <br />
            <h4 className="SA_service_title" style={{ fontSize: "18px" }}>
              {" "}
              {data?.section4?.data[1]?.heading}
            </h4>
            <p className="SA_service_text">
            {data?.section4?.data[1]?.text}
            </p>

            <br />
            <h4 className="SA_service_title" style={{ fontSize: "18px" }}>
              {" "}
              {data?.section4?.data[2]?.heading}
            </h4>
            <p className="SA_service_text">
            {data?.section4?.data[2]?.text}
            </p>
          </div>
          <div
            data-aos="fade-right"
            style={{
              width: "320px",
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "50px",
            }}
          >
            <h4 className="SA_service_title" style={{ fontSize: "18px" }}>
              {" "}
              {data?.section4?.data[3]?.heading}
            </h4>
            <p className="SA_service_text">
            {data?.section4?.data[3]?.text}
            </p>
            <br />
            <h4 className="SA_service_title" style={{ fontSize: "18px" }}>
              {" "}
              {data?.section4?.data[4]?.heading}
            </h4>
            <p className="SA_service_text">
            {data?.section4?.data[4]?.text}
            </p>
            <br />
            <h4 className="SA_service_title" style={{ fontSize: "18px" }}>
              {" "}
              {data?.section4?.data[5]?.heading}
            </h4>
            <p className="SA_service_text">
            {data?.section4?.data[5]?.text}
            </p>
          </div>
          <div
            data-aos="fade-right"
            style={{
              width: "350px",
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={data?.section4?.img} alt="" />
          </div>
        </div>
      </div>
      <ContactBanner/>
    </div>
  );
}

export default Services;
