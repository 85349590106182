import React from "react";
import section1 from "../../Assets/Images/section2.jpg";
import { BlogCard } from "./Blogcart";
import icon1 from '../../Assets/Images/icon1.png'
import icon2 from '../../Assets/Images/icon2.png'
import icon3 from '../../Assets/Images/icon3.png'
import icon4 from '../../Assets/Images/icon4.png'
import icon5 from '../../Assets/Images/icon5.png'
import icon6 from '../../Assets/Images/icon6.png'
import ContactBanner from "../../Container/Contact/ContactBanner";
import { NavLink } from "react-router-dom";
function Home() {
  return (
    <>
      <div className="mainContainer">
        <div className="childContainer">
          <div className="left1">
            <img
              style={{ maxWidth: "90%", height: "auto", borderRadius: "15px" }}
              src={section1}
              alt="Section 1"
            />
            <h1
              style={{ color: "black", marginTop: "10px", fontSize: "30px" }}
            >
              Pioneering progress,
built on <span style={{ color: "#0a86e3" }}>15 years</span> of continuous learning.<br /> 
            </h1>
            {/* <div className="left1child">
              <h2 style={{ color: "white", fontSize: "35px" }}>14</h2>
              <h3 style={{ color: "white" }}>
                Years Of Experiences <br /> In Company
              </h3>
            </div> */}
          </div>
          <div className="right1">
            <h1 style={{ marginTop: "5px", fontSize: "35px" }}>
              Accounting, Audit, Taxation & Corporate Advisory Firm
            </h1>
            <p style={{ opacity: "0.8", marginTop: "10px",textAlign:"justify" }}>
              Established in 2010, Kirti Corporate Advisory Private Limited is a
              dynamic consultancy firm specializing in comprehensive tailored
              solutions for businesses seeking excellence in bookkeeping,
              accounting, and payroll services. Our team of dedicated
              professionals is committed to delivering unparalleled expertise
              and support, ensuring your financial operations run seamlessly.
              With a focus on precision, efficiency, and client satisfaction, we
              provide customized solutions to meet the unique needs of your
              business. 
            </p>
            <br />
            <NavLink to='/about-us'>            <button className="Mainbtn">Read More</button>
</NavLink>
          </div>
        </div>
      </div>
      <h1
        style={{
          textAlign: "center",
          color: "#043757",
          marginTop: "100px",
          fontSize: "30px",
        }}
      >
        What We Do
      </h1>
      <p
        style={{
          textAlign: "center",
          color: "black",
          marginTop: "10px",
          fontSize: "25px",
        }}
      >
        
        {" "}
        Partner with us for a streamlined approach to financial management,{" "}
        <br /> empowering your organization to thrive in today's competitive
        landscape.
      </p>

      <div className="mainContainer" style={{ marginTop: "20px" }}>
        <div className="servicesContainer">
          <NavLink to='/services?id=Accounting-and-Bookkeeping-Services' className='h_e_bx'>
            <img
              src={icon1}
              style={{height:"55px"}}
              alt="Save Taxes & Build Wealth"
              title="Save Taxes & Build Wealth"
            />
            <h3 style={{fontSize:"20px"}}>
            Accounting and  <br/>Bookkeeping Services
            </h3>
            <p>
            At AskKCA, our Finance Expert offer tailored accounting and bookkeeping services to streamline your financial processes, ensuring efficiency and compliance. Focus on propelling your business forward with confidence.
            </p>
            </NavLink>

          <NavLink to='/services?id=Payroll-Management-and-Compliance-Services' className="h_e_bx">
            <img
              src={icon2}
              style={{height:"55px"}}
              alt="Save Taxes & Build Wealth"
              title="Save Taxes & Build Wealth"
            />
           <h3 style={{fontSize:"20px"}}>
           Payroll   and  <br/> Compliance Services
            </h3>
            <p>
            AskKCA provides expert Payroll and Compliance Services to ensure accurate payroll processing and regulatory compliance for your business. Let us handle payroll complexities so you can focus on driving your business forward.
            </p>
          </NavLink>
          <NavLink to='/services?id=US-Book-Keeping-Services' className="h_e_bx">
            <img
              src={icon3}
              style={{height:"55px"}}
              alt="Save Taxes & Build Wealth"
              title="Save Taxes & Build Wealth"
            />
           <h3 style={{fontSize:"20px"}}>

           US Book Keeping <br/> Services
            </h3>
            <p>
            At AskKCA, we provide meticulous bookkeeping services tailored for US businesses. Our expert team ensures your financial records are accurate, organized, and up-to-date. This allows you to focus on running and growing your business with peace of mind.



            </p>
          </NavLink>
          <NavLink to='/services?id=Direct-and-Indirect-Tax-Compliances' className="h_e_bx">
            <img
              src={icon4}
              style={{height:"55px"}}
              alt="Save Taxes & Build Wealth"
              title="Save Taxes & Build Wealth"
            />
           <h3 style={{fontSize:"20px"}}>
           Direct and Indirect <br/> Tax Compliances            </h3>
            <p>
            Ensure seamless Direct and Indirect Tax Compliances with AskKCA. Our experienced team navigates complex tax regulations, ensuring your business meets all compliance requirements. Trust us to handle your tax matters efficiently, allowing you to focus on your core business activities.
            </p>
          </NavLink>
          <NavLink to='/services?id=Secretarial-Compliances' className="h_e_bx">
            <img
              src={icon5}
              style={{height:"55px"}}
              alt="Save Taxes & Build Wealth"
              title="Save Taxes & Build Wealth"
            />
            <h3 style={{fontSize:"20px"}}>
            Secretarial <br/>Compliances            </h3>
            <p>
            Trust AskKCA for expert Secretarial Compliances. Our dedicated team ensures your business meets all regulatory requirements seamlessly. Let us handle your secretarial obligations so you can focus on growing your business with confidence.
            </p>
          </NavLink>
          <NavLink to='/services?id=Business-Registration' className="h_e_bx">
            <img
              src={icon6}
              style={{height:"55px"}}
              alt="Save Taxes & Build Wealth"
              title="Save Taxes & Build Wealth"
            />
            <h3 style={{fontSize:"20px"}}>
            	Business <br/> Registration
            </h3>
            <p>
            Streamline your Business Registration process with AskKCA. Our experts handle all aspects of registration efficiently, ensuring compliance and peace of mind. Let us assist you in establishing your business with confidence and clarity.
            </p>
          </NavLink>
        </div>
      </div>
      <h1
        style={{
          textAlign: "center",
          color: "#043757",
          marginTop: "100px",
          fontSize: "30px",
        }}
      >
        LATEST BLOGS & UPDATES
      </h1>
      <p
        style={{
          textAlign: "center",
          color: "black",
          marginTop: "10px",
          fontSize: "25px",
        }}
      >
       In our multi-disciplinary services, we serve across various industries <br/> & diverse verticals,  thus generating deep insights into 
      </p>
      <div className="mainContainer" style={{ marginTop: "20px" }}>
        <div className="servicesContainer">
            <BlogCard/>
            </div></div>
            <ContactBanner/>
    </>
  );
}

export default Home;
