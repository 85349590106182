import React from 'react'
import BlogDetail from '../../../Pages/BlogDetail'
import Fotter from '../../../Container/Fotter/Fotter'
function Details() {
  return (
    <div>
        <BlogDetail/>
        <Fotter/>
    </div>
  )
}

export default Details