import React,{useState,useEffect} from 'react'
import Blog_cart from '../Container/Blogs/Cart'
import { NavLink } from "react-router-dom";
import './Blog.css'
import MainBlog from '../Assets/Images/blogimg1.png'
import { useSearchParams } from "react-router-dom";
import { BlogCard } from './Home/Blogcart';
import { db } from "../firebase/config";
function BlogDetail() {
  const [detailData, setDetailData] = useState(null);
  const [blogs, setBlogs] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("blog");

  const getBlogDetail = async () => {
    try {
      const querySnapshot = await db.collection("Bogs").where("url", "==", id).get();

      if (querySnapshot.empty) {
        console.log("No matching blog found");
        return;
      }

      const blogData = querySnapshot.docs[0].data(); // Assuming there's only one matching blog
      setDetailData(blogData);
      console.log(blogData)
    } catch (error) {
      console.error('Error fetching blog detail:', error);
    }
  };

  const getBlogs = async () => {
    try {
      const querySnapshot = await db.collection("Bogs").limit(4).get();

      if (querySnapshot.empty) {
        console.log("No blogs found");
        return;
      }

      const blogList = querySnapshot.docs.map((doc) => doc.data());
      setBlogs(blogList);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogDetail(); // Fetch blog detail when component mounts or id changes
    getBlogs(); // Fetch latest blogs when component mounts
  }, [id]); // Trigger effect whenever id changes

  return (
    <div>
        <div className='headercontiner2'>
        <div className='headercontinerText'>
         <h1 style={{fontSize:"30px",color:"white"}}>Blogs</h1>
         <p style={{fontSize:"20px",color:"white"}}>Easiest Way of e-filing your Income Tax Return in India</p>
        </div>
      </div>
      <div
        className="Ablogs_section2_container"
        style={{ marginBottom: "50px",marginTop:"70px" }}
      >
        <div className="Blog_d_container">
          <div className="Blog_d_l_container">
            <p
              className="Home_blog_box_img_btn"
              style={{
                width: "100px",
                height: "30px",
                textAlign: "center",
                paddingTop: "5px",
                fontSize: "16px",
                backgroundColor: "#CADFFF",
                color: "#0D47A1",
              }}
            >
              Blog
            </p>

            <div style={{ maxWidth: "750px", marginTop: "15px" }}>
              <img style={{ width: "90%",borderRadius:"17px" }} src={detailData?.img} alt="" />
            </div>
            <div className="blog_text_content_container">

              <h2 className="blog_heading">{detailData?.title}</h2>
              <div>
      <div style={{fontFamily:"Inter"}} dangerouslySetInnerHTML={{ __html: detailData?.blog  }}></div>
    </div>
             
            </div>
          </div>

          <div>
            <div className="Blog_recent_heading">Recent Blogs</div>
            <div className="Blog_d_r_container" style={{gap:"30px"}}>
              {/* {blogs.map((ell) => (
                <>
                  <Blog_cart
                    title={ell.blog_tittle}
                    id={ell.blog_id}
                    img={ell.img}
                    info={ell.info}
                    name={ell.blog_name}
                  />
                </>
              ))} */}
              <BlogCard/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogDetail