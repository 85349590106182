import logo from './logo.svg';
import './App.css';
import Header from "../src/Container/Navbar/header/Header"
import Home from './Containers/Pages/Home/Home';
import Contact from '../src/Containers/Pages/Contact';
import About from '../src/Containers/Pages/About';
import AllBlogs from './Containers/Pages/Blogs/AllBlog';
import BlogDetails from './Containers/Pages/Blogs/Details';
import { Routes, Route, NavLink } from "react-router-dom";
import ContactBanner from './Container/Contact/ContactBanner';
import Service from './Containers/Pages/Services/Index'
function App() {
  return (
    <div className="">
      <div className='header'>
      <Header/>
      </div>
      <Routes>

      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />

                    <Route path="/blog-detail" element={<BlogDetails />} />
                    <Route path="/blogs" element={<AllBlogs />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/services" element={<Service />} />
   
</Routes>

    </div>
  );
}

export default App;
