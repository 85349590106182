import React, { useEffect, useRef, useState } from 'react';
import styles from "./header.module.scss";

import websiteLogo from "./../../../Assets/Images/logo.png";

import Services from './services/Services';
import Solutions from './solutions/Solutions';
import Capabilities from './capabilities/Capabilities';
import Companies from './companies/Companies';
import Resources from './resources/Resources';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const responsiveContainer = useRef(null);
    const handleContactClick = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const [responsiveOpen, setResponsiveOpen] = useState(false);
    const toggleResponsive = () => {
        setResponsiveOpen(responsiveOpen => !responsiveOpen);
    };
    useEffect(() => {
        if (!responsiveOpen) {
            responsiveContainer.current.style.height = 0;
            document.querySelector(".line1").style.transform = "translateY(-8px) rotate(0)";
            document.querySelector(".line2").style.opacity = 1;
            document.querySelector(".line3").style.transform = "translateY(8px) rotate(0)";
        } else {
            responsiveContainer.current.style.height = "300px";
            document.querySelector(".line1").style.transform = "translateY(-8px) rotate(35deg)";
            document.querySelector(".line2").style.opacity = 0;
            document.querySelector(".line3").style.transform = "translateY(8px) rotate(-35deg)";
        }
        
    }, [responsiveOpen]);

    const [accordionClick, setAccordionClick] = useState({
        services: false,
        solutions: false,
        company: false,
        resources: false,
    });

    const handleAccordionClick = (name) => {
        if (name === "services") {
            setAccordionClick({ ...accordionClick, services: !accordionClick.services });
        } else if (name === "solutions") {
            setAccordionClick({ ...accordionClick, solutions: !accordionClick.solutions });
        } else if (name === "company") {
            setAccordionClick({ ...accordionClick, company: !accordionClick.company });
        } else if (name === "resources") {
            setAccordionClick({ ...accordionClick, resources: !accordionClick.resources });
        }
        
    };

    useEffect(() => {
        // console.log(accordionClick);
        if (accordionClick.services === true) {
            document.querySelector(".servicesAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".servicesNavLinksContainer").style.height = "208px";
        } else {
            document.querySelector(".servicesAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".servicesNavLinksContainer").style.height = "0";
        }

        if (accordionClick.solutions === true) {
            document.querySelector(".solutionsAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".solutionsNavLinksContainer").style.height = "108px";
        }
        //  else {
        //     document.querySelector(".solutionsAccordionSign").style.transform = "rotateZ(0)";
        //     document.querySelector(".solutionsNavLinksContainer").style.height = "0";
        // }

        if (accordionClick.company === true) {
            document.querySelector(".companyAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".companyNavLinksContainer").style.height = "135px";
        }
        //  else {
        //     document.querySelector(".companyAccordionSign").style.transform = "rotateZ(0)";
        //     document.querySelector(".companyNavLinksContainer").style.height = "0";
        // }

        if (accordionClick.resources === true) {
            document.querySelector(".resourcesAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".resourcesNavLinksContainer").style.height = "80px";
        }
        //  else {
        //     document.querySelector(".resourcesAccordionSign").style.transform = "rotateZ(0)";
        //     document.querySelector(".resourcesNavLinksContainer").style.height = "0";
        // }
    }, [accordionClick]);

    return (
        <div  className={styles.container}>
            <div className={styles.row1}>
                <NavLink className={styles.headerImageContainer} style={{width:"200px"}} to="/home">
                    <img className={styles.headerImage} src={websiteLogo}  style={{width:"100px"}}  alt="Askmeidentity" />
                </NavLink>
                <div className={styles.navContainer}>
                <NavLink to='/' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Home
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                    <NavLink to='/services?id=Accounting-and-Bookkeeping-Services' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Services
                        <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div>
                    </div></NavLink>
                    <NavLink to='/blogs' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Blogs
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                    
                    <NavLink to='/about-us' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        About
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                 

                    <NavLink  to="/contact-us" className={`${styles.pageLinkContainer} ${styles.contact}`}>Contact</NavLink>

                    <div onClick={toggleResponsive} className={styles.dropDownIconContainer}>
                        <div className={`${styles.line} ${styles.line1} line1`}></div>
                        <div className={`${styles.line} ${styles.line2} line2`}></div>
                        <div className={`${styles.line} ${styles.line3} line3`}></div>
                    </div>
                </div>
            </div>
            <div ref={responsiveContainer} className={styles.responsiveContainer}>
                <div className={styles.responsiveInnerContainer}>
                <NavLink  onClick={toggleResponsive}  style={{textDecoration:"none",fontFamily:"Inter"}}  to="/" className={styles.contactLink}>
                       Home
                    </NavLink>
                    <div onClick={() => handleAccordionClick("services")} className={`${styles.servicesLink} servicesLink`}>
                     <NavLink to='/services?id=Accounting-and-Bookkeeping-Services'  style={{textDecoration:"none"}}> <div style={{fontFamily:"Inter"}} className={styles.servicesText}>Services</div></NavLink>  
                        <div className={`${styles.servicesAccordionSign} servicesAccordionSign`}></div>
                    </div>
                    <div className={`${styles.servicesNavLinks} ${styles.navLinksContainer} servicesNavLinksContainer`}>
                    <NavLink  onClick={toggleResponsive}  to="/services?id=Accounting-and-Bookkeeping-Services" className={styles.subNavLink}>Accounting and Bookkeeping Service</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/services?id=Payroll-Management-and-Compliance-Services" className={styles.subNavLink}>Payroll Management and Compliance Service</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services?id=US-Book-Keeping-Services" className={styles.subNavLink}>US Book Keeping Service</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services?id=Direct-and-Indirect-Tax-Compliances" className={styles.subNavLink}>Direct and Indirect Tax Service</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services?id=Secretarial-Compliances" className={styles.subNavLink}>Secretarial Compliances Service</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services?id=Business-Registration" className={styles.subNavLink}>Business Registration Service</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services?id=Virtual-CFO-in-Services" className={styles.subNavLink}>Virtual CFO in Services</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services?id=Call-Centre-in-Services" className={styles.subNavLink}>Call Centre in Services</NavLink>

                    </div>

                  
                    <NavLink  onClick={toggleResponsive}  style={{textDecoration:"none",fontFamily:"Inter"}}  to="/about-us" className={styles.contactLink}>
                        About us
                    </NavLink>
                    <NavLink  onClick={toggleResponsive}  style={{textDecoration:"none",fontFamily:"Inter"}}  to="/contact-us" className={styles.contactLink}>
                        Contact
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Header
