import React,{useState} from 'react'
import './About.css'
import { NavLink } from "react-router-dom";

function About() {
  const [Active, setActive] = useState("Vission");
  const data={
    mission:"We pride ourself on saying we are “ India ka Tax Expert”. We not just take care of our client's taxation needs but are committed to our mission of educating the masses about taxes to end their fear associated with taxes to make a winning experience .",
    vission:"We pride ourself on saying we are . We not just take care of our client's taxation needs but are committed to our mission of educating the masses about taxes to end their fear associated taxes to make a winning experience for all.",
    value:"We pride ourself on saying we are “ India ka Tax Expert”.  committed to our mission of educating the masses about taxes to end their fear associated with taxes to make a winning experience for all."
  }
  return (
    <div>
      <div className='headercontiner1'>
        <div className='headercontinerText'>
         <h1 style={{fontSize:"30px",color:"white"}}>About Us</h1>
         <p style={{fontSize:"20px",color:"white"}}>Easiest Way of e-filing your Income Tax Return in India</p>
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"100px"}}>
        <div className='innerAboutPage'>
        <div className='left1'>
  <div className="left">
    <hr className="hr" />
    <h2>
      We strongly believe that best professional services should be available to
      everyone .
    </h2>
  </div>
</div>
<div className='right1'>
  <h2 style={{fontSize:"30px"}}>
  This belief drives us to do what we do to win your taxes.
  </h2>
  <p style={{fontSize:"18px"}}>We pride ourself on saying we are “ India ka Tax Expert”.
We not just take care of our client's taxation needs but are committed to our mission of educating the masses about taxes to end their fear associated with taxes to make a winning experience for all .Partner with us for a streamlined approach to financial
              management.</p>
</div>

        </div>
      </div>
  <div style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"100px",marginBottom:"100px"}}>
    <div className='missioncontiner'>
    <nav className="About_nav">
    <NavLink
    to=""
    className={Active == "Vission" ? "About_navcomp_active" : "About_navcomp"}
    onClick={() => setActive("Vission")}
>
    Our Vision
</NavLink>
<NavLink
    to=""
    className={Active == "Mission" ? "About_navcomp_active" : "About_navcomp"}
    style={{ marginLeft: "50px" }}
    onClick={() => setActive("Mission")}
>
    Our Mission
</NavLink>
<NavLink
    to=""
    className={Active == "Values" ? "About_navcomp_active" : "About_navcomp"}
    style={{ marginLeft: "50px" }}
    onClick={() => setActive("Values")}
>
    Our Values
</NavLink>

            </nav>
            <hr className="About_hr" style={{}} />
            <div>
              {Active === "Mission" && (
   <p className='About_info' style={{marginTop:"20px",opacity:"0.8"}}>EsOur mission is to empower businesses with accurate financial insights, meticulous bookkeeping services, and personalized financial guidance. We strive to be a trusted partner, offering unparalleled support in navigating the complexities of financial management and ensuring compliance with regulatory requirements. <br/>By providing reliable and efficient bookkeeping solutions, we aim to help our clients achieve their financial goals and thrive in a dynamic business environment.  </p>              )}
              {Active === "Vission" && (
   <p className='About_info' style={{marginTop:"20px",opacity:"0.8"}}>Our mission is to empower businesses with accurate financial insights, meticulous bookkeeping services, and personalized financial guidance. <br/>We strive to be a trusted partner, offering unparalleled support in navigating the complexities of financial management and ensuring compliance with regulatory requirements. By providing reliable and efficient bookkeeping solutions, we aim to help our clients achieve their financial goals and thrive in a dynamic business environment.  </p>              )}
              {Active === "Values" && (
   <p className='About_info' style={{marginTop:"20px",opacity:"0.8"}}> At our core, AskKCA embodies integrity, reliability, and a commitment to excellence. We uphold the highest ethical standards, ensuring transparency and accountability in all interactions. Our dedication to delivering accurate and timely bookkeeping services builds trust and confidence with our clients. We prioritize client needs, tailoring solutions to exceed expectations.<br/> Collaboration and teamwork are central to our culture, valuing diverse talents and perspectives. Continuous learning keeps us abreast of industry trends, offering value-added services.<br/> Additionally, we engage in community initiatives, supporting local businesses and social causes for a better future. Join us in our journey of ethical, client-centric, and community-focused service. </p>              )}
            </div>
    </div>

  </div>
  {/* <h1 style={{fontSize:"30px",textAlign:"center",color:"#094f7b",marginTop:"100px",fontWeight:"bold"}}>Our Customer </h1> */}

{/* <div  style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20px"}}>
<div className='missioncontiner' style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
<div className='logoimg'><img src="https://tax2win.in/assets-new/img/clients-logos/sbi-logo.png" alt="SBI Bank" title="SBI Bank" width="135" height="25"/></div>
<div className='logoimg'><img src="https://tax2win.in/assets-new/img/clients-logos/sbi-logo.png" alt="SBI Bank" title="SBI Bank" width="135" height="25"/></div>
<div className='logoimg'><img src="https://tax2win.in/assets-new/img/clients-logos/sbi-logo.png" alt="SBI Bank" title="SBI Bank" width="135" height="25"/></div>
<div className='logoimg'><img src="https://tax2win.in/assets-new/img/clients-logos/sbi-logo.png" alt="SBI Bank" title="SBI Bank" width="135" height="25"/></div>
</div>
</div> */}
    </div>
  )
}

export default About