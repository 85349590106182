import React from 'react'
import './Blog.css'
import { NavLink } from 'react-router-dom'
import MainBlog from '../Assets/Images/blogimg1.png'
import Blog_cart from '../Container/Blogs/Cart'
function AllBlogs({blogs}) {
  //  const blogs=[1,2,,3,3,4,4]
  console.log(blogs)
  return (
    <div>
        <div className='headercontiner2'>
        <div className='headercontinerText'>
         <h1 style={{fontSize:"30px",color:"white"}}>Blogs</h1>
         <p style={{fontSize:"20px",color:"white"}}>Easiest Way of e-filing your Income Tax Return in India</p>
        </div>
      </div>
      <div className="Ablogs_section2_container" style={{marginTop:"70px"}}>
        <div className="Ablogs_section2_container1">
         
          <h1
            className="Ablogs_section2_heading"
            style={{ paddingLeft: "20px" }}
          >
            Featured Blog Post
          </h1>
          
        </div>
      </div>
      <div className="Ablogs_section2_container">
        <div
          className="Ablogs_section2_container1"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div style={{ maxWidth: "500px",maxHeight:"370px", padding: "20px",position:'relative' }}>
            <img style={{ width: "100%",height:"100%",borderRadius:"10px" }} src={MainBlog} alt="" />
            <img style={{position:"absolute",top:"25px",left:"30px",width:"150px"}} src='' alt="" />
          </div>
          <div className="Ablogs_section2_text_container">
            <h2 className="Ablogs_section2_title">
            Essential Bookkeeping Tips For Small Businesses </h2>
            <p className="Ablogs_section2_info">
            In the world of small business, effective bookkeeping is essential for maintaining financial health and facilitating growth. Whether you're a seasoned entrepreneur or just starting out, mastering the basics of bookkeeping can save you time, money, and headaches down the road.            </p>
            <div className="read_more_btn">
              <NavLink to="/resources/blog-detail?id=top-10-iam-trends-in-2023-and-beyond" className="read_more_text">
                Read More
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="Ablogs_section2_container">
        <div
          className="Ablogs_section2_container1"
          style={{ maxWidth: "1200px", paddingLeft: "20px" }}
        >
          <h3 className="ABlogs_heading">All blogs</h3>
        </div>
      </div>
      <div className="Ablogs_section2_container">
        <div
          className="Ablogs_section2_container1"
          style={{
            maxWidth: "1200px",
            display: "flex",
            flexWrap: "wrap",
            gap:"50px",
            marginBottom: "50px",
          }}
        >
          {/* <Blog_cart
            title="Top 10 Identity and Access Management Trends in 2023 and Beyond"
            id="21542"
            img='https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/blogs%2Funsplash_Tyg0rVhOTrE%20(16).png?alt=media&token=5eca4f4e-eefa-4051-8f29-d4398aa6d3e4'
            info="Enterprises have long struggled with identity and data security. Due to changing technologies and work environments, data, identity, and access management are increasingly important."
          />
          <Blog_cart
            title="IAM Market Outlook 2023 and Beyond"
            id="955574"
            img='https://firebasestorage.googleapis.com/v0/b/sharpits-f9cb4.appspot.com/o/blogs%2Funsplash_Tyg0rVhOTrE%20(17).png?alt=media&token=725f2f52-9cb5-4bad-8051-4e13e9a42a5b'
            info="The global Identity and Access Management Market is expected to reach USD 18.89 Billion in 2023, growing 12.9%, surpassing $25.8 billion by 2026, and rising 18.2% during the forecast period 2021–2026. BYOD and cloud services will drive the market.

"
          /> */}

          {blogs?.map((ell) => (
           <Blog_cart
           title={ell.title}
           id={ell.url}
           img={ell.img}
           info={ell.subtitle}
           name={ell.url}
         />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AllBlogs