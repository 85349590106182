import React from 'react'
import Fotter from '../../Container/Fotter/Fotter'
import Header from '../../Pages/Home/Header'
import AboutPage from '../../Pages/About'
import ContactBanner from '../../Container/Contact/ContactBanner'
function About() {
  return (
    <div>
        {/* <Header/> */}
        <AboutPage/>
        <ContactBanner/>
        <Fotter/>
    </div>
  )
}

export default About