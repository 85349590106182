import React from 'react'
import '../..//Pages/Home/allCss.css'
import { NavLink } from 'react-router-dom'
function Cart(data) {
  return (
    <>
    <NavLink to={`/blog-detail?blog=${data.id}`}>
         <div className="card">
          <div className="card__header">
            <img
              src={data.img}
              alt="card__image"
              className="card__image"
              width={600}
              style={{height:"200px"}}
            />
          </div>
          <div  style={{height:"300px"}} className="card__body">
            <span className="tag tag-blue">Fianace</span>
            <h4>{data.title}</h4>
            <p       
>
              {data.info}
            </p>
          </div>
          
        </div>
    </NavLink>
    </>
  )
}

export default Cart