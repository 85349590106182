import React from 'react'
import styles from "./services.module.scss";
import img2 from './images/icons8-leader-50.png'
import call from './images/icons8-call-center-50.png'
import innovation from "./images/innovation-1 1 (Traced).png";
import consultation from "./images/consultation 1 (Traced).png";
import vector from "./images/Vector.png";
import techSupport from "./images/tech-support 1 (Traced).png";
import management from "./images/management-service 1 (Traced).png";
import { NavLink } from 'react-router-dom';

const Services = () => {
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <NavLink  to="/services?id=Accounting-and-Bookkeeping-Services" className={styles.section1Container}>
                    <div className={styles.iconContainer}>
                        <img src={innovation} alt="innovation" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer}>
                         Accounting and Bookkeeping Services
                        </div>
                        
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/services?id=Payroll-Management-and-Compliance-Services" className={styles.section1Container}>
                    <div className={styles.iconContainer}>
                        <img src={techSupport} alt="tech support" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer}>
                         Payroll Management and Compliance Services
                        </div>
                       
                    </div>
                </NavLink>

                <div className={styles.hr}></div>

                <NavLink  to="/services?id=US-Book-Keeping-Services" className={styles.section3Container}>
                    <div className={styles.iconContainer}>
                        <img src={vector} alt="vector" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer} style={{marginTop:"27px"}}>
                         US Book Keeping Services
                        </div>
                        
                    </div>
                </NavLink>
                <div className={styles.hr}></div>

                <NavLink  to="/services?id=Virtual-CFO-in-Services" className={styles.section3Container}>
                    <div className={styles.iconContainer}>
                        <img src={img2} alt="vector" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer} style={{marginTop:"27px"}}>
                        Virtual CFO in Services          
                                      </div>
                        
                    </div>
                </NavLink>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.rightContainer}>
            <NavLink  to="/services?id=Direct-and-Indirect-Tax-Compliances" className={styles.section2Container}>
                    <div className={styles.iconContainer}>
                        <img src={management} alt="management" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer}>
                         Direct and Indirect Tax Compliances
                        </div>
                      
                    </div>
                </NavLink>
              

                <div className={styles.hr}></div>

                <NavLink  to="/services?id=Secretarial-Compliances" className={styles.section2Container}>
                    <div className={styles.iconContainer}>
                        <img src={consultation} alt="consultation" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer} style={{marginTop:"27px"}}>
                         Secretarial Compliances
                        </div>
                       
                    </div>
                </NavLink>
                <div className={styles.hr}></div>

                <NavLink  to="/services?id=Business-Registration" className={styles.section2Container}>
                    <div className={styles.iconContainer}>
                        <img src={consultation} alt="consultation" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer} style={{marginTop:"27px"}}>
                         Business Registration
                        </div>
                       
                    </div>
                </NavLink>
                <div className={styles.hr}></div>

                <NavLink  to="/services?id=Call-Centre-in-Services" className={styles.section3Container}>
                    <div className={styles.iconContainer}>
                        <img src={call} alt="vector" />
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.headingContainer} style={{marginTop:"27px"}}>
                        Call Centre in Services
                        </div>
                        
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default Services
