import React,{useEffect,useState} from 'react'
import Img from '../../Assets/Images/headImg.jpg'
import './allCss.css'
function Header() {
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const array = [
  //   {
  //     title: "Digital Identity Transformation",
  //     text: "Accelerate a Modern and Innovative NextGen IAM platform",
  //   },
  //   {
  //     title: "Custom Built IAM Solutions",
  //     text: "Tailored Solutions and Integrations for Your IGA, EAM, PAM and Customer IAM Needs",
  //   },
  //   {
  //     title: "IAM Advisory and Assessment",
  //     text: " Against Industry Best Practices, Identify and Remediate Gaps and Issues, Define Effective IAM Strategy and Roadmap",
  //   },
  //   {
  //     title: "IAM Top Talented Resources ",
  //     text: "Large Pool of High Quality Experts in All Major IAM Technologies Available Upon Your Request",
  //   },
  //   {
  //     title: "IAM Managed Services",
  //     text: "Monitor and Manage Your IAM Systems, Continuous Support and Advancement",
  //   },
  // ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((currentIndex) => (currentIndex + 1) % array.length);
  //   }, 4000);
  //   return () => clearInterval(interval);
  // }, [array.length]);
  const [slideimg, setSlideImg] = useState('');
  let [ind, setInd] = useState(1);
//  const img = [sliderimg1, sliderimg2, sliderimg1, sliderimg2];
  const [title, setTitle] = useState("Accounting and Bookkeeping Services");
  const [htext, setHtext] = useState(
    "Accelerate a Modern and Innovative NextGen IAM platform"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const text = [
    {title:"Welcome to Kirti Corporate Advisory Private Limited",
      text:""
    },
    {
      title: "Accounting and Bookkeeping Services",
      text: "Accelerate a Modern and Innovative NextGen IAM platform",
    },
    {
      title: "Payroll Management and Compliance Services",
      text: "Tailored Solutions and Integrations for Your IGA, EAM, PAM and Customer IAM Needs",
    },
    {
      title: "US Book Keeping Services",
      text: " Against Industry Best Practices, Identify and Remediate Gaps and Issues, Define Effective IAM Strategy and Roadmap",
    },
    {
      title: "Direct and Indirect Tax Compliances ",
      text: "Large Pool of High Quality Experts in All Major IAM Technologies Available Upon Your Request",
    },
    {
      title: "Secretarial Compliances",
      text: "Monitor and Manage Your IAM Systems, Continuous Support and Advancement",
    },
    {
      title: "Business Registration",
      text: "Monitor and Manage Your IAM Systems, Continuous Support and Advancement",
    },
  ];
 // let len = img.length;
  useEffect(() => {
    const interval = setInterval(() => {
      // setSlideImg(img[ind % img.length ]);
      setTitle(text[ind % text.length].title);
      setHtext(text[ind % text.length].text);

      setInd(ind + 1);
    }, 3970);

    return () => clearInterval(interval);
  }, [ind]);

  // const slider =()=>{

  // }
  const [currentIndex, setCurrentIndex] = useState(0);
  const array = [
    {title:"Welcome to Kirti Corporate Advisory Private Limited",
      text:""
    },
    {
      title: "Accounting and Bookkeeping Services",
      text: "These services involve recording financial transactions such as sales, purchases, receipts, and payments in accounting software or ledgers.",
    },
    {
      title: "Payroll and Compliance Services",
      text:"Our payroll management services ensure accurate and compliant processing of employee salaries, taxes, and benefits, allowing you to focus on your core business.",
    },
    {
      title: "US Book Keeping Services",
      text: "Our US bookkeeping services cater to businesses operating in the United States, ensuring compliance with US financial regulations and reporting standards.",
    },
    {
      title: "Direct and Indirect Tax Compliances ",
      text: "Our direct and indirect tax compliance services ensure that businesses comply with income tax, TDS, advance tax, tax audit, and GST regulations, minimizing tax liabilities and regulatory risks.",
    },
    {
      title: "Secretarial Compliances",
      text: "We specialize in managing income tax compliance, TDS deductions, advance tax payments, and GST filings, providing comprehensive support to meet tax obligations.",
    },
    {
      title: "Business Registration",
      text: "We specialize in guiding businesses through the registration process, providing expertise in forming private limited companies, LLPs, one-person companies, proprietorships, and partnerships.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % array.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [array.length]);
  return (
    <>
  <div>
  <div className="Sevice_header">
        <div style={{ width: "100%", height: "500px" }}>
          <img
            className="s_Header_img"
            style={{ width: "100%" }}
            src={Img}
            alt=""
          />
        </div>
        <div className="Overlay"></div>
        {/* <div style={{ padding: "10px" }} className="Service_heading">
          <h2 className="S_heading">Our Services</h2>
          <h1 className="animate-text">{array[currentIndex].title}</h1>

        </div> */}
        <div className="home_content">
            {/* <Text title={title} info={htext}/> */}
            <div style={{ textAlign: "center" }}>
              {/* <h2 data-aos="fade-down" className="home_header_title">
              {title}
            </h2>
            <p data-aos="fade-up" className="home_header_text">
              {" "}
              {htext}{" "}
            </p> */}
              <div>
                <h1 className="animate-text"  style={{textAlign:"center"}}>{array[currentIndex].title}</h1>
                <p className="animate-text1" style={{maxWidth:"700px",textAlign:"center"}}>{array[currentIndex].text}</p>
              </div>
            </div>
          </div>
      </div>
      <div>
        <div>
          
        </div>
      </div>
  </div>
    </>
    )
}

export default Header