import React,{useState,useEffect} from 'react';
import './allCss.css'
import { db } from '../../firebase/config';
import img from '../../Assets/Images/headImg2.jpg'
import { NavLink } from 'react-router-dom';
  export function BlogCard() {
    const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection("Bogs").limit(3).get();

        if (snapshot.empty) {
          console.log("No blogs found");
          return;
        }

        const blogList = [];
        snapshot.forEach((doc) => {
          const blogData = doc.data();
          if (blogData.title) { // Check if the blog has the 'flag' property set to true
            blogList.push(blogData);
          }
          console.log(blogData)

        });

        setBlogs(blogList);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchData(); // Fetch blogs when component mounts
  }, []);
    return (
        <div className="container">
                    {blogs?.map((data) => (
        <NavLink to={`/blog-detail?blog=${data.url}`}>
        <div className="card">
         <div className="card__header">
           <img
             src={data.img}
             alt="card__image"
             className="card__image"
             width={600}
             style={{height:"200px"}}
           />
         </div>
         <div  style={{height:"300px"}} className="card__body">
           <span className="tag tag-blue">Fianace</span>
           <h4>{data.title}</h4>
           <p       
>
             {data.subtitle}
           </p>
         </div>
         
       </div>
   </NavLink>
          ))}
       
       
      </div>
      
    );
  }