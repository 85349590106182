import React,{useState,useEffect} from 'react'
import AllBlogs from '../../../Pages/AllBlogs'
import Fotter from '../../../Container/Fotter/Fotter'
import { db } from "../../../firebase/config";
import ContactBanner from '../../../Container/Contact/ContactBanner';
function AllBlog() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection("Bogs").get();

        if (snapshot.empty) {
          console.log("No blogs found");
          return;
        }

        const blogList = [];
        snapshot.forEach((doc) => {
          const blogData = doc.data();
          if (blogData.title) { // Check if the blog has the 'flag' property set to true
            blogList.push(blogData);
          }
          console.log(blogData)

        });

        setBlogs(blogList);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchData(); // Fetch blogs when component mounts
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div><AllBlogs blogs={blogs}/>
    <ContactBanner/>
    <Fotter/></div>
  )
}

export default AllBlog