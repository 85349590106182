import React ,{useState,useEffect}from 'react'
import Services from '../../../Pages/Services/Services'
import Fotter from '../../../Container/Fotter/Fotter'
import { useSearchParams } from 'react-router-dom'
import Header from '../../../Assets/Images/Services.png';
import section1 from '../../../Assets/Images/services1.jpg';
import Service2 from '../../../Assets/Images/service2.png'
import sepec1 from '../../../Assets/Images/spec1.jpg'
import book1 from '../../../Assets/Images/book1.jpg'
import reg1 from '../../../Assets/Images/regis1.jpg'
import tax1 from '../../../Assets/Images/tax1.jpg'
import pay1 from '../../../Assets/Images/pay1.jpg'
import sepec2 from '../../../Assets/Images/spec2.png'
import book2 from '../../../Assets/Images/book2.png'
import reg2 from '../../../Assets/Images/res2.png'
import tax2 from '../../../Assets/Images/tax2.png'
import pay2 from '../../../Assets/Images/pay2.png'
import sepec3 from '../../../Assets/Images/spec3.png'
import book3 from '../../../Assets/Images/book3.png'
import reg3 from '../../../Assets/Images/reg2.png'
import tax3 from '../../../Assets/Images/tsx3.png'
import pay3 from '../../../Assets/Images/pay3.png'
import acc2 from '../../../Assets/Images/acc2.png'
import cfo1 from '../../../Assets/Images/pexels-sora-shimazaki-5668858.jpg'
import cc from '../../../Assets/Images/pexels-yankrukov-8867482.jpg'
import cfo2 from '../../../Assets/Images/cfo1.png'
import cc1 from '../../../Assets/Images/cc1.png'
function Index() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [data,setData]=useState('')
  var id = searchParams.get("id")
useEffect(() => {
  window.scrollTo(0, 0);

  if(id=='Accounting-and-Bookkeeping-Services'){
    setData(serviceData[0])
  }else if(id=='Payroll-Management-and-Compliance-Services'){
    setData(serviceData[1])
  }else if(id=='US-Book-Keeping-Services'){
    setData(serviceData[2])

  }else if(id=='Direct-and-Indirect-Tax-Compliances'){
    setData(serviceData[3])
  }else if(id=='Secretarial-Compliances'){
    setData(serviceData[4])

  }else if(id=='Business-Registration'){
    setData(serviceData[5])
  
  }else if(id=='Call-Centre-in-Services'){
    setData(serviceData[7])
  }else {
    setData(serviceData[6])

  }

 
}, [id])


  return (
    <div><Services data={data}/>
    <br/>
    <br/>
    <Fotter/></div>
  )
}

export default Index
var serviceData=[
  {
    "section1": {
      "img": acc2,
      "heading": "Accounting and Bookkeeping Services",
      "text": "These services includes providing essential financial record-keeping, analysis, and reporting, ensuring compliance and aiding businesses in optimizing their financial performance."
    },
    "section2": {
      "img": section1,
      "heading": "Why Askkca for Accounting and Bookkeeping Services",
      "text1": "This service focuses on managing a company's accounts payable, which involves recording and managing supplier invoices, processing payments, and maintaining accurate records of outstanding bills.",
      "text2": "Our accounting and bookkeeping services encompass the meticulous recording of financial transactions such as sales, purchases, receipts, and payments. This includes maintaining journals, ledgers, and subsidiary books."
    },
    "section3": {
      "heading": "Why Choose Askkca for Accounting and Bookkeeping Services",
      "data": [
        {
          "heading": "Expertise in Financial Management",
          "text": "Our team comprises seasoned financial professionals with extensive experience in accounting and bookkeeping, ensuring accurate and compliant financial records."
        },
        {
          "heading": "Tailored Financial Reporting",
          "text": "We provide customized financial reports and analysis to help you gain insights into your business's financial health and make informed decisions."
        },
        {
          "heading": "Efficient Accounts Payable Management",
          "text": "We streamline accounts payable processes, ensuring timely payments to suppliers and optimizing cash flow management."
        },
       
        {
          "heading": "Dedicated Client Support",
          "text": "We offer personalized support and guidance, assisting you with any accounting or bookkeeping-related queries or challenges."
        },
        {
          "heading": "Accounts Payable Reciveable, Aging",
          "text": "At ASKKCA, we optimize accounts payable, receivable, and aging reports, enhancing efficiency and cash flow while offering valuable financial insights."
        },
       
        {
          "heading": "Monthly Review Of Financial Status",
          "text": " Trust us to deliver precise financial insights, empowering you to make informed decisions and drive growth."
        }
      ]
    },
    "section4": {
      "heading": "Benefits of Askkca Accounting and Bookkeeping Services",
      "img": Service2,
      "data": [
        {
          "heading": "Enhanced Financial Transparency",
          "text": "Our services enhance transparency in financial reporting, providing stakeholders with clear and accurate financial information."
        },
        {
          "heading": "Risk Mitigation and Compliance",
          "text": "We help mitigate financial risks and ensure compliance with accounting standards and regulations, reducing potential penalties and liabilities."
        },
        {
          "heading": "Cost-Efficient Operations",
          "text": "Outsourcing accounting and bookkeeping tasks to Askkca can lead to cost savings compared to maintaining an in-house finance team."
        },
        {
          "heading": "Focus on Core Business Activities",
          "text": "By entrusting us with accounting functions, you can redirect your focus and resources towards core business activities and strategic initiatives."
        },
        {
          "heading": "Improved Decision-Making",
          "text": "Access to accurate financial data and reports empowers informed decision-making, driving business growth and profitability."
        },
        {
          "heading": "Financial Management",
          "text": "We offer a holistic approach to financial management, covering all aspects from bookkeeping to financial analysis and strategic planning."
        }
      ]
    }
  }
  ,
  {
    "section1": {
      "img": pay3,
      "heading": "Payroll Management and Compliance Services",
      "text": "Our payroll management services ensure accurate and compliant processing of employee salaries, taxes, and benefits, allowing you to focus on your core business."
    },
    "section2": {
      "img": pay1,
      "heading": "Why Askkca for Payroll Management and Compliance",
      "text1": "We specialize in payroll processing, managing employee payroll, statutory compliance, leave management, and tax calculations, ensuring adherence to labor laws and regulations.",
      "text2": "Our comprehensive HR and payroll solutions include employee self-service portals (ESS), payroll software as a service (SaaS), and outsourcing options for seamless payroll operations."
    },
    "section3": {
      "heading": "Why Choose Askkca for Payroll Management",
      "data": [
        {
          "heading": "Expert Payroll Processing",
          "text": "Our team handles payroll processing efficiently, ensuring accurate and timely payments to employees while managing tax deductions and compliance."
        },
        {
          "heading": "Statutory Compliance Management",
          "text": "We keep track of labor laws and regulations, ensuring compliance with payroll-related legal requirements and minimizing compliance risks."
        },
        {
          "heading": "Employee Self-Service Portals (ESS)",
          "text": "Our ESS portals empower employees to manage their payroll information, including viewing payslips and updating personal details, enhancing transparency and efficiency."
        },
        {
          "heading": "Comprehensive HR Support",
          "text": "In addition to payroll, we offer integrated HR solutions to streamline human resource management and improve workforce efficiency."
        },
        {
          "heading": "Scalable and Flexible Solutions",
          "text": "Our payroll services are scalable and adaptable to accommodate businesses of all sizes, from startups to large enterprises."
        },
        {
          "heading": "Cost-Effective Outsourcing",
          "text": "Outsourcing payroll to Askkca can lead to cost savings compared to maintaining an in-house payroll team, reducing administrative burdens and overhead costs."
        }
      ]
    },
    "section4": {
      "heading": "Benefits of Askkca Payroll Management Services",
      "img": pay2,
      "data": [
        {
          "heading": "Efficiency and Accuracy",
          "text": "Our payroll services ensure efficient and accurate processing, reducing errors and ensuring timely salary payments."
        },
        {
          "heading": "Enhanced Compliance",
          "text": "We help businesses stay compliant with payroll-related laws and regulations, minimizing legal risks and penalties."
        },
        {
          "heading": "Focus on Core Business Activities",
          "text": "By outsourcing payroll management to us, you can allocate resources towards core business functions and strategic initiatives."
        },
        {
          "heading": "Improved Employee Satisfaction",
          "text": "Timely and transparent payroll processing enhances employee satisfaction and morale, contributing to a positive work environment."
        },
        {
          "heading": "Access to Expertise",
          "text": "Tap into our payroll expertise and industry knowledge, benefiting from best practices and tailored solutions."
        },
        {
          "heading": "Customizable Solutions",
          "text": "We offer customizable payroll solutions to meet specific business needs and accommodate unique payroll requirements."
        }
      ]
    }
  }
  ,
  {
    "section1": {
      "img": book3,
      "heading": "US Bookkeeping Services",
      "text": "Our US bookkeeping services cater to businesses operating in the United States, ensuring compliance with US financial regulations and reporting standards."
    },
    "section2": {
      "img": book1,
      "heading": "Why Askkca for US Bookkeeping Services",
      "text1": "We specialize in US-specific bookkeeping tasks such as bank reconciliation, payroll processing, and financial statement preparation, tailored to meet US accounting standards.",
      "text2": "Our services also include expense tracking, inventory management, and comprehensive financial analysis and reporting for businesses with operations in the US market."
    },
    "section3": {
      "heading": "Why Choose Askkca for US Bookkeeping",
      "data": [
        {
          "heading": "Expertise in US Accounting Standards",
          "text": "Our team has in-depth knowledge of US GAAP (Generally Accepted Accounting Principles) and IRS regulations, ensuring accurate and compliant financial reporting."
        },
        {
          "heading": "Customized US Financial Reporting",
          "text": "We provide customized financial reports tailored to US regulatory requirements, helping businesses make informed financial decisions."
        },
        {
          "heading": "Efficient Bank Reconciliation",
          "text": "Our bank reconciliation services ensure accurate matching of US bank transactions with accounting records, promoting financial accuracy and transparency."
        },
        {
          "heading": "US Payroll Processing",
          "text": "We handle US payroll processing, including tax calculations and compliance with US payroll laws and regulations."
        },
        {
          "heading": "Comprehensive Expense Management",
          "text": "Our services cover tracking and managing US business expenses, optimizing cost control and financial efficiency."
        },
        {
          "heading": "Dedicated US Market Focus",
          "text": "We specialize in supporting businesses with operations in the US market, offering tailored solutions to meet specific US accounting and bookkeeping needs."
        }
      ]
    },
    "section4": {
      "heading": "Benefits of Askkca US Bookkeeping Services",
      "img": book2,
      "data": [
        {
          "heading": "Regulatory Compliance",
          "text": "Stay compliant with US financial regulations and reporting standards, minimizing risks and ensuring legal adherence."
        },
        {
          "heading": "Accurate Financial Reporting",
          "text": "Our US bookkeeping services ensure accurate and transparent financial reporting, fostering trust and credibility with stakeholders."
        },
        {
          "heading": "Focused Business Support",
          "text": "Focus on growing your business in the US market while we handle critical bookkeeping tasks and financial management."
        },
        {
          "heading": "Timely and Reliable Services",
          "text": "We deliver timely and reliable bookkeeping services, allowing you to make timely business decisions based on up-to-date financial information."
        },
        {
          "heading": "Scalable Solutions",
          "text": "Our services are scalable to accommodate business growth and evolving financial needs in the dynamic US market environment."
        },
        {
          "heading": "Professional Expertise",
          "text": "Benefit from our team's professional expertise and industry knowledge, ensuring high-quality bookkeeping services tailored to your business."
        }
      ]
    }
  }
  ,
  {
    "section1": {
      "img": tax3,
      "heading": "Direct and Indirect Tax Compliances",
      "text": "Our direct and indirect tax compliance services ensure that businesses comply with income tax, TDS, advance tax, tax audit, and GST regulations, minimizing tax liabilities and regulatory risks."
    },
    "section2": {
      "img": tax1,
      "heading": "Why Askkca for Direct and Indirect Tax Compliances",
      "text1": "We specialize in managing income tax compliance, TDS deductions, advance tax payments, and GST filings, providing comprehensive support to meet tax obligations.",
      "text2": "Our services cover all aspects of direct and indirect taxes, ensuring accurate calculations, timely filings, and proactive tax planning to optimize tax savings."
    },
    "section3": {
      "heading": "Why Choose Askkca for Tax Compliances",
      "data": [
        {
          "heading": "Expert Tax Advisory",
          "text": "Our team of tax experts provides personalized tax advisory services, helping businesses navigate complex tax laws and optimize tax strategies."
        },
        {
          "heading": "Timely Compliance and Filing",
          "text": "We ensure timely compliance with tax regulations, including income tax returns, TDS deductions, advance tax payments, and GST filings, minimizing penalties and late fees."
        },
        {
          "heading": "Comprehensive Tax Audit Support",
          "text": "We assist businesses in preparing for and undergoing tax audits, ensuring readiness and compliance during tax assessments."
        },
        {
          "heading": "GST Compliance Expertise",
          "text": "Our GST compliance services cover registration, return filing, reconciliation, and compliance with GST laws, ensuring smooth operations in the GST regime."
        },
        {
          "heading": "Proactive Tax Planning",
          "text": "We offer proactive tax planning strategies to optimize tax savings and minimize tax liabilities, aligning with business objectives and financial goals."
        },
        {
          "heading": "Regulatory Updates and Advisory",
          "text": "Stay informed about changes in tax laws and regulations with our regular updates and advisory services, ensuring ongoing compliance and proactive tax management."
        }
      ]
    },
    "section4": {
      "heading": "Benefits of Askkca Tax Compliance Services",
      "img": tax2,
      "data": [
        {
          "heading": "Reduced Tax Risks",
          "text": "Mitigate tax risks and exposure through accurate tax calculations, timely filings, and compliance with regulatory requirements."
        },
        {
          "heading": "Cost Savings",
          "text": "Optimize tax planning strategies to maximize tax savings and reduce overall tax liabilities, enhancing financial efficiency."
        },
        {
          "heading": "Professional Guidance",
          "text": "Receive expert guidance and advisory from our tax professionals, ensuring compliance and strategic tax management."
        },
        {
          "heading": "Efficient Tax Operations",
          "text": "Streamline tax operations with our comprehensive tax compliance services, allowing businesses to focus on core operations."
        },
        {
          "heading": "Peace of Mind",
          "text": "Rest assured knowing that your tax compliance requirements are handled professionally, minimizing stress and administrative burdens."
        },
        {
          "heading": "Customized Solutions",
          "text": "Benefit from tailored tax solutions designed to meet specific business needs and industry requirements."
        }
      ]
    }
  }
  ,
  {
    "section1": {
      "img": sepec3,
      "heading": "Secretarial Compliances",
      "text": "Our secretarial compliance services ensure businesses meet regulatory requirements related to annual filings, board meetings, director appointments, AGMs, compliance certificates, and statutory registers."
    },
    "section2": {
      "img": sepec1,
      "heading": "Why Askkca for Secretarial Compliances",
      "text1": "We specialize in handling annual filings, drafting board meeting minutes, managing director appointments and resignations, conducting AGMs, issuing compliance certificates, and maintaining statutory registers.",
      "text2": "Our services cover all aspects of secretarial compliances, ensuring legal adherence, timely submissions, and proper documentation to meet corporate governance standards."
    },
    "section3": {
      "heading": "Why Choose Askkca for Secretarial Compliances",
      "data": [
        {
          "heading": "Comprehensive Regulatory Expertise",
          "text": "Our team possesses extensive knowledge of corporate laws and regulations, providing expert guidance on secretarial compliances and governance matters."
        },
        {
          "heading": "Timely and Accurate Filings",
          "text": "We ensure timely and accurate completion of annual filings, board meeting documentation, and compliance certificates, minimizing risks of non-compliance."
        },
        {
          "heading": "Efficient Management of Directorial Changes",
          "text": "We handle appointments, resignations, and changes in directorship, ensuring compliance with regulatory formalities and maintaining updated records."
        },
        {
          "heading": "AGM Facilitation",
          "text": "Our services include organizing and facilitating Annual General Meetings (AGMs), ensuring compliance with AGM requirements and statutory obligations."
        },
        {
          "heading": "Statutory Register Maintenance",
          "text": "We maintain statutory registers and records as per regulatory requirements, providing businesses with organized and up-to-date documentation."
        },
        {
          "heading": "Professional Compliance Certificates",
          "text": "We issue compliance certificates to certify adherence to regulatory compliances, enhancing transparency and credibility for businesses."
        }
      ]
    },
    "section4": {
      "heading": "Benefits of Askkca Secretarial Compliances Services",
      "img": sepec2,
      "data": [
        {
          "heading": "Legal Compliance Assurance",
          "text": "Ensure compliance with corporate laws and regulations, reducing legal risks and potential penalties for non-compliance."
        },
        {
          "heading": "Focus on Core Business Operations",
          "text": "Delegate secretarial compliances to us and focus on core business activities, improving operational efficiency and productivity."
        },
        {
          "heading": "Professional Documentation Management",
          "text": "Benefit from systematic and organized documentation management for secretarial compliances, facilitating audit and regulatory inspections."
        },
        {
          "heading": "Expert Guidance and Support",
          "text": "Receive expert guidance and support on corporate governance matters, leveraging our team's expertise to navigate regulatory complexities."
        },
        {
          "heading": "Enhanced Corporate Governance",
          "text": "Maintain high standards of corporate governance with our comprehensive secretarial compliance services, fostering trust and credibility."
        },
        {
          "heading": "Tailored Solutions",
          "text": "Access customized solutions tailored to your business needs and industry requirements, ensuring effective management of secretarial compliances."
        }
      ]
    }
  }
  ,
  {
    "section1": {
      "img": reg3,
      "heading": "Business Registration Services",
      "text": "Mention Company formation, Offshore Company Formation, Company Registration in US and UAE"
    },
    "section2": {
      "img": reg1,
      "heading": "Why Askkca for Business Registration",
      "text1": "We specialize in guiding businesses through the registration process, providing expertise in forming private limited companies, LLPs, one-person companies, proprietorships, and partnerships.",
      "text2": "Our services also include assisting with registrations such as Startup India, MSME, GST, and Trademark, ensuring compliance with legal requirements and facilitating smooth business establishment."
    },
    "section3": {
      "heading": "Why Choose Askkca for Business Registration",
      "data": [
        {
          "heading": "Comprehensive Entity Formation",
          "text": "We offer comprehensive support for forming different business entities, tailored to meet specific business objectives and legal structures."
        },
        {
          "heading": "Expert Guidance on Registrations",
          "text": "Our team provides expert guidance on registrations like Startup India, MSME, GST, and Trademark, assisting businesses in obtaining relevant certifications and benefits."
        },
        {
          "heading": "Streamlined Registration Process",
          "text": "We streamline the registration process, ensuring all necessary documentation and formalities are completed accurately and efficiently."
        },
        {
          "heading": "Offshore Company Formation",
          "text": " ASKKCA specializes in offshore company formation, offering expert guidance and seamless incorporation services."
        },
        {
          "heading": "Startup Support and Facilitation",
          "text": "For startups, we offer specialized support in obtaining Startup India registration and other necessary certifications to kickstart business operations."
        },
        {
          "heading": "Business Registration In US And UAE",
          "text": "ASKKCA offers expert assistance in business registration in both the US and UAE. Our seasoned professionals streamline the process, ensuring compliance with regulations and smooth incorporation."
        }
      ]
    },
    "section4": {
      "heading": "Benefits of Askkca Business Registration Services",
      "img": reg2,
      "data": [
        {
          "heading": "Legal Clarity and Compliance",
          "text": "Gain legal clarity and compliance assurance through our professional business registration services, ensuring proper establishment and recognition of business entities."
        },
        {
          "heading": "Access to Government Benefits",
          "text": "Obtain access to government schemes and benefits by leveraging our expertise in obtaining registrations like Startup India, MSME, GST, and Trademark."
        },
        {
          "heading": "Efficient Business Setup",
          "text": "Facilitate efficient business setup and registration process, reducing administrative burdens and ensuring timely commencement of business activities."
        },
        {
          "heading": "Expertise and Guidance",
          "text": "Benefit from our team's expertise and guidance throughout the business registration journey, from entity formation to obtaining necessary certifications."
        },
        {
          "heading": "Support for Growth and Expansion",
          "text": "Lay a strong foundation for business growth and expansion with our comprehensive business registration services, positioning your business for success."
        },
        {
          "heading": "Tailored Support for Startups",
          "text": "Enable startups to navigate the complex registration landscape with ease, receiving specialized support to launch and scale their ventures."
        }
      ]
    }
  },
  {
    "section1": {
        "img": sepec3,
        "heading": "Virtual CFO Services",
        "text": "Our Virtual CFO services offer strategic financial guidance and oversight without the expense of a full-time CFO. We assist businesses in financial planning, analysis, budgeting, forecasting, and decision-making to drive growth and profitability."
    },
    "section2": {
        "img": cfo1,
        "heading": "Why Choose Askkca for Virtual CFO Services",
        "text1": "With our Virtual CFO services, you gain access to experienced financial professionals who provide tailored solutions to meet your business needs.",
        "text2": "We offer cost-effective alternatives to hiring a full-time CFO, allowing you to benefit from expert financial guidance without the overhead costs.",
        "text3": "Our Virtual CFOs collaborate closely with your team, offering insights and recommendations to optimize financial performance and achieve strategic objectives."
    },
    "section3": {
        "heading": "Why Choose Askkca for Virtual CFO Services",
        "data": [
            {
                "heading": "Strategic Financial Planning",
                "text": "Our Virtual CFOs assist in developing strategic financial plans aligned with your business goals, ensuring sound financial management and resource allocation."
            },
            {
                "heading": "Data-Driven Decision Making",
                "text": "We provide data-driven insights and analysis to support informed decision-making, enabling you to seize opportunities and mitigate risks effectively."
            },
            {
                "heading": "Budgeting and Forecasting",
                "text": "Our Virtual CFOs help in creating budgets and forecasts to track financial performance and guide resource allocation, fostering sustainable growth and profitability."
            },
            {
                "heading": "Financial Performance Analysis",
                "text": "We conduct in-depth analysis of financial performance, identifying key metrics and trends to optimize operational efficiency and maximize profitability."
            },
            {
                "heading": "Cash Flow Management",
                "text": "Our Virtual CFOs develop strategies for effective cash flow management, ensuring liquidity and financial stability for your business."
            },
            {
                "heading": "Investor Relations and Fundraising Support",
                "text": "We provide support in investor relations and fundraising activities, helping you attract investment and secure funding for business expansion."
            }
        ]
    },
    "section4": {
        "heading": "Benefits of Askkca Virtual CFO Services",
        "img": cfo2,
        "data": [
            {
                "heading": "Cost Efficiency",
                "text": "Save on overhead costs associated with hiring a full-time CFO while still benefiting from expert financial guidance tailored to your business."
            },
            {
                "heading": "Flexibility and Scalability",
                "text": "Our Virtual CFO services offer flexibility and scalability to adapt to your evolving business needs, ensuring optimal resource utilization and growth."
            },
            {
                "heading": "Access to Expertise",
                "text": "Tap into the expertise of seasoned financial professionals who provide strategic insights and guidance to drive your business forward."
            },
            {
                "heading": "Focus on Core Business Functions",
                "text": "Free up time and resources to focus on core business functions, while our Virtual CFOs handle financial planning and analysis."
            },
            {
                "heading": "Improved Financial Performance",
                "text": "Benefit from improved financial performance through strategic financial planning, analysis, and decision-making guided by our Virtual CFOs."
            },
            {
                "heading": "Long-Term Growth Strategy",
                "text": "Our Virtual CFOs help in developing long-term growth strategies, ensuring sustainable business growth and profitability over time."
            }
        ]
    }
},{
  "section1": {
      "img": pay3,
      "heading": "Call Centre Services",
      "text": "Our Call Centre services provide comprehensive inbound and outbound call support, as well as virtual assistance solutions, to enhance customer service and operational efficiency. Whether you need customer support, telemarketing, appointment scheduling, or virtual receptionist services, we have you covered."
  },
  "section2": {
      "img": cc,
      "heading": "Why Choose Askkca for Call Centre Services",
      "text1": "With our Call Centre services, you can rely on our experienced team to handle your customer interactions with professionalism and efficiency.",
      "text2": "We offer flexible and scalable solutions tailored to your specific requirements, ensuring seamless integration with your existing operations.",
      "text3": "Our Call Centre services are equipped with advanced technology and tools to optimize performance, improve response times, and enhance customer satisfaction."
  },
  "section3": {
      "heading": "Why Choose Askkca for Call Centre Services",
      "data": [
          {
              "heading": "Inbound Call Support",
              "text": "Our inbound call support services include customer inquiries, order processing, technical support, and help desk solutions, ensuring prompt and efficient resolution of customer issues."
          },
          {
              "heading": "Outbound Call Services",
              "text": "We provide outbound call services such as telemarketing, lead generation, market research surveys, and follow-up calls, helping you reach out to potential customers and expand your business reach."
          },
          {
              "heading": "Virtual Assistance Solutions",
              "text": "Our virtual assistance solutions cover a wide range of tasks, including email management, appointment scheduling, data entry, and administrative support, freeing up your time to focus on core business activities."
          },
          {
              "heading": "24/7 Availability",
              "text": "We offer round-the-clock availability for your call centre needs, ensuring that your customers can reach you anytime, anywhere, enhancing customer satisfaction and loyalty."
          },
          {
              "heading": "Multilingual Support",
              "text": "Our call centre services include multilingual support, allowing you to cater to diverse customer needs and expand your business globally."
          },
          {
              "heading": "Quality Assurance",
              "text": "We maintain stringent quality assurance measures to ensure that every interaction with your customers meets the highest standards of professionalism and service excellence."
          }
      ]
  },
  "section4": {
      "heading": "Benefits of Askkca Call Centre Services",
      "img": cc1,
      "data": [
          {
              "heading": "Enhanced Customer Service",
              "text": "Deliver exceptional customer service experiences with our responsive and efficient call centre solutions, enhancing customer satisfaction and loyalty."
          },
          {
              "heading": "Cost Efficiency",
              "text": "Save on overhead costs associated with setting up and maintaining an in-house call centre, while still benefiting from professional call centre services tailored to your needs."
          },
          {
              "heading": "Scalability",
              "text": "Our call centre services offer scalability to accommodate your business growth and fluctuating call volumes, ensuring uninterrupted support for your customers."
          },
          {
              "heading": "Increased Productivity",
              "text": "Offload routine tasks and administrative duties to our virtual assistants, allowing your team to focus on high-value activities and strategic initiatives."
          },
          {
              "heading": "Improved Efficiency",
              "text": "Optimize your business operations with streamlined call centre processes, advanced technology, and trained professionals, leading to improved efficiency and productivity."
          },
          {
              "heading": "Global Reach",
              "text": "Expand your business reach and tap into new markets with our multilingual call centre services, catering to diverse customer demographics and geographic locations."
          }
      ]
  }
}


  
]