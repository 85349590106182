import firebase from 'firebase/compat/app';
import  'firebase/compat/auth';
import 'firebase/compat/analytics'
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAgX6GVh-6tvhRgF4Flm1If5urg8qpbUPA",
  authDomain: "askkca-c3073.firebaseapp.com",
  projectId: "askkca-c3073",
  storageBucket: "askkca-c3073.appspot.com",
  messagingSenderId: "140951083348",
  appId: "1:140951083348:web:5b4eb9bbe8c79d5dc210c1",
  measurementId: "G-SYH28SZT1J"
  };

   
// const firebaseApp=firebase.initializeApp(firebaseConfig);
// export const db=firebase.firestore();
//  export const auth =firebaseApp.auth();



   
const firebaseApp=firebase.initializeApp(firebaseConfig);
export const db=firebase.firestore();
 export const auth =firebaseApp.auth();
 
 export const storage = getStorage(firebaseApp);

