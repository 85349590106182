import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import firebaseDB from "./firebase";
import { db } from "../firebase/config";
import icon from './Images/icons1.png'
import icon1 from './Images/icon2.png'
import icon2 from './Images/icon4.png'
import icon3 from './Images/icon3.png'
import icon4 from './Images/icon5.png'
import icon5 from './Images/icon6.png'
import icon6 from './Images/icon7.png'
import icon7 from './Images/icon8.png'
import "./Contact.css";
function Contact() {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = state;
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!name || !email || !subject || !message) {
      toast.error("Please provide value in each input field");
    } else {
      try {
        const snapshot = await db.collection("ContactUs").add(state);
        setState({ name: "", email: "", subject: "", message: "" });
        toast.success("Request Raised Successfully");
      } catch (error) {
        console.error("Error submitting form: ", error);
        // Handle error (e.g., show error message to the user)
      }
      
    }
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <section className="contact-section">
        <div data-aos="fade-up" className="Contacttitle">
        Contact Us Today!

</div>

      <div data-aos="fade-up" className="Contactmaintext">
        <div>
        Our dedicated Customer Support team excels in legal services, driven by a deep passion for both our work and our clients. Our commitment to excellence shines through in the exceptional service we provide. Whether it's business or tax registration needs, we are eager to find effective solutions for you. <br />
        <br />
        While based in Hyderabad with an in-house team, we boast a vast network of professionals across numerous cities. Our services are delivered entirely online, eliminating the need for physical presence at your location.
        <br />
        Reach out to us and experience the difference in legal support!






        <br />
      

        </div>
       
      </div>
      <div className="container">
        <ToastContainer position="top-center" />
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="wrapper">
              <div className="row no-gutters">
              <div className="col-md-6 d-flex align-items-stretch">
                  <div className="info-wrap w-100 p-lg-5 p-4 img">
                    <h3>Contact us</h3>
                    <p className="mb-4">
                      We're open for any suggestion or just to have a chat
                    </p>
                    <div className="dbox w-100 d-flex align-items-start">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-map-marker"><img style={{width:"35px"}} src={icon1}/></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Address:</span> 4 Salkia School Road 
                          Howrah - 711101                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone"><img style={{width:"35px"}} src={icon}/></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Phone:</span>
                          <a href="tel://123456789">+91 96740 00666</a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-paper-plane"><img style={{width:"35px"}} src={icon3 }/></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Email:</span>
                          <a href="mailto:info@yoursite.com">
                          support@askkca.con                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox w-100 d-flex align-items-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-globe"><img style={{width:"35px"}} src={icon2}/></span>
                      </div>
                      <div className="text pl-3">
                        <p>
                          <span>Website:</span>
                          <a href="#">askkca.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-wrap w-100 p-lg-5 p-4">
                    <h3 className="mb-4">Send us a message</h3>
                    <form
                      id="contactForm"
                      className="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              onChange={handleInputChange}
                              value={name}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              onChange={handleInputChange}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              onChange={handleInputChange}
                              value={subject}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              type="text"
                              className="form-control"
                              name="message"
                              placeholder="Message"
                              cols="30"
                              rows="6"
                              onChange={handleInputChange}
                              value={message}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="submit"
                              value="Send Message"
                              className="btn btn-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_boxes">
    <div className="review-box">
  <img
    src={icon4}
    alt="Google Rating"
    title="Google Rating"
  />
  <p>
  100+ Clients
    <br />
    
  </p>
</div>
<li className="review-box">
  <img
    src={icon5}
    alt="Google Rating"
    title="Google Rating"
  />
  <p>
   Secure and
    <br />
    Safe
  </p>
</li><li className="review-box">
  <img
    src={icon6}
    alt="Google Rating"
    title="Google Rating"
  />
  <p>
  Authorized by Income<br />
Tax Department
    
    
  </p>
</li><li className="review-box">
  <img
    src={icon7}
    alt="Google Rating"
    title="Google Rating"
  />
  <p>
    4.8 Google
    <br />
    Rating
  </p>
</li>
    </div>
    </section>
  );
}

export default Contact;