import React from 'react'
import Contact1 from '../../Pages/Contact'
import Fotter from '../../Container/Fotter/Fotter'
function Contact() {
  return (
    <div><Contact1/>
    <Fotter/>
    
    </div>
  )
}

export default Contact